<template>
  <div ref="refApp" v-if="canUseRouter" class="app-container" :class="classNames">
    <p class="version" v-if="isModeShow">
      {{ mode }}
      <template v-if="wni.isNative">
        {{ app?.deviceInfo?.app_version }}
      </template>
      {{ webVersion }}
    </p>

    <router-view />

    <ui-toast />
    <ui-drawer />

    <resize-observer @notify="onResize" />
  </div>

  <div v-else class="app-container" :class="classNames">
    <page-launch :status="appStatus" @initialize="onInitialize" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UiDrawer from '@/components/commons/drawer/Drawer.vue';
import UiToast from '@/components/commons/toast/Toast.vue';
import PageLaunch from '@/pages/common/launch/PageLaunch.vue';
import { useWNInterface } from '@/plugins/vue-wni';
import { APP_STATUS, useAppStore } from '@/stores/modules/app';
import { useDrawerStore } from '@/stores/modules/drawer';
import { useLayoutStore } from '@/stores/modules/layout';
import { useSessionStore } from '@/stores/modules/session';
import { useToastStore } from '@/stores/modules/toast';
import logger from '@/utils/logger';

const debug = logger('app:main');
debug.enabled = true;

const router = useRouter();
const route = useRoute();
const wni = useWNInterface();
const drawerModule = useDrawerStore();

const app = useAppStore();
const session = useSessionStore();
const layout = useLayoutStore();

const state = reactive({
  isReady: false,
  error: null,
});
const androidExit = reactive({
  is_exit: false,
  timeout: null,
});

const canUseRouter = computed((value) => {
  if (!state.isReady) {
    return false;
  }

  return appStatus.value === APP_STATUS.LAUNCHED || appStatus.value === APP_STATUS.EXTERNAL;
});

const mode = computed(() => app.config.mode);

const isModeShow = computed(() => (mode.value !== 'production' ? true : false));

const classNames = computed(() => [
  layoutMode.value !== 'unset' && layoutMode.value,
  isDarkMode.value && 'dark',
  mode.value !== 'production' && mode.value,
]);

const appStatus = computed(() => app.status);

const appAppearance = computed(() => (!state.isReady ? 'unset' : app.currentAppearance));

const isDarkMode = computed(() => {
  if (!state.isReady) {
    return false;
  }
  return appAppearance.value === 'dark' ? true : false;
});

const webVersion = computed(() => app.config.version);

const layoutMode = computed(() => layout.layoutMode);

watch(appStatus, (value) => {
  debug('appStatus', value);
});

onBeforeMount(async () => {
  try {
    app.setStatus(APP_STATUS.LAUNCHING);

    if (wni.isNative) {
      await initWNInterface();
    }
    await initializeStores();

    state.isReady = true;
  } catch (e) {
    app.setStatus(APP_STATUS.ERROR);
  }
});

onMounted(async () => {
  if (wni.isNative) {
    await onDynamicLink();
  }
});

async function initializeStores() {
  await app.init();
  await session.init();
  await layout.init();
}

function initWNInterface() {
  return new Promise((resolve, reject) => {
    debug('initWNInterface');
    wni.onReady((e) => {
      wni.onAppear((e) => {
        onDarkMode();
        onSetBadgeNumber();
      });

      wni.onBack(() => {
        if (wni.isAndroid) {
          if (drawerModule.items.length > 0) {
            drawerModule.dismissAll();
            return;
          }

          if (!history.state.back && route.name !== 'Home') {
            if (route.name === 'MyBookDetailTo') {
              return router.replace({ name: 'MySpaceManage', params: { id: route.query.space_id } });
            } else if (route.name === 'MySpaceManage') {
              router.replace({ name: 'MySpaces' });
            } else if (route.name === 'MySpaces') {
              router.replace({ name: 'Mypage' });
            } else if (route.name === 'MyBookDetailFrom') {
              router.replace({ name: 'MyBookFrom' });
            } else if (route.name === 'MyBookFrom') {
              router.replace({ name: 'Mypage' });
            } else if (route.name === 'Chat') {
              router.replace({ name: 'ChatList' });
            } else {
              router.replace({ name: 'Home' });
            }

            return;
          }

          if (!history.state.back && route.name === 'Home') {
            tryToExit();
            return;
          }

          router.back();
        }
      });

      wni.onKeyboard((data) => {
        if (data.isVisibility === true) {
          useLayoutStore().setKeyboardHeight(data.height);
        } else {
          useLayoutStore().setKeyboardHeight(0);
        }
      });

      resolve(e);
    });
  });
}

function tryToExit() {
  if (androidExit.is_exit) {
    wni.execute('wnAppClose', {});
    androidExit.is_exit = false;
    return;
  }

  androidExit.is_exit = true;
  androidExit.timeout = setTimeout(() => {
    androidExit.timeout = null;
    androidExit.is_exit = false;
  }, 2000);

  useToastStore().alert({ message: '유후를 종료하려면 다시 탭하세요' });
}

function onDynamicLink() {
  wni.execute('wnStartLinking', {});
  wni.onLink(async (e) => {
    console.log('#### onLink : ', e);

    if (!e) {
      return;
    }

    try {
      const { link } = e;
      const url = new URL(link);
      const { pathname, search } = url;
      if (pathname === '/space') {
        const id = url.searchParams.get('space_id');
        await router.replace({ name: 'SpaceDetail', params: { id } });
        return;
      }

      await router.replace(pathname);
      return;
    } catch (err) {
      console.log('onLink err : ', err);
    } finally {
    }
  });
}

function onSetBadgeNumber(number = 0) {
  debug('onSetBadgeNumber', number);

  wni.execute('wnPushBadgeNumber', {
    number,
  });
}

function onResize() {
  debug('onResize');

  layout.resize({});
}

async function onInitialize(e) {
  debug('onInitialize', e);
}

function onDarkMode() {
  debug('onDarkMode');
}
</script>

<style lang="scss" scoped>
.version {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  font-size: unit(10);
}
</style>
