import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/vue';

const plugin = {
  install: (app: any) => {
    app.component('Swiper', Swiper);
    app.component('SwiperSlide', SwiperSlide);
  },
};

export default plugin;
