import { cloneDeep, filter } from 'lodash';
import { mappingDistrictMainData } from '@/data/district';
import { spaceFacilityList, spaceSizeList } from '@/data/space';

const PYEONG_CONVERSION_CONSTANT = 3.30579;
export function getSpaceSizeLabel(value) {
  const size = spaceSizeList.find((item) => item.value === value);

  if (!size) {
    return `${value}평`;
  }
  return size.label;
}

export function getSpaceFacilityList(filterFn: Function) {
  return filter(cloneDeep(spaceFacilityList), filterFn);
}

/**
 *
 * 제곱미터, 평 환산법
 * 제곱미터 * 0.3025 = 평
 * 평 * 3.3058 = 제곱미터
 */
// 제곱미터 -> 평수 변환
export function convertSqmToPy(sqm) {
  const py = sqm * 0.3025;
  return py;
}

export function convertPyToSqm(py) {
  const sqm = Number(py) * 3.3058;
  return Math.round(sqm * 10) / 10;
}

export const calcFloorSize = (width: number, height: number) => {
  if (!width && !height) {
    return null;
  }

  const w = width / 100;
  const h = height / 100;
  const squareM = w * h;
  let squareKr = convertSqmToPy(squareM);

  /**
   * (정책 v1)
   * - 소수점 이하 둘째자리 버림 (ex, 3.14 -> 3.1)
   */
  if (squareKr && Math.floor(squareKr * 10) / 10 === 0) {
    squareKr = 0.1;
  }

  return {
    sqm: Math.round(squareM * 10) / 10,
    py: Math.round(squareKr * 10) / 10,
  };
};

export const convertCmToM = (cm) => {
  return cm / 100 + 'm' || null;
};

// 공간 크기에 따라 월 단위 예상이용료
export const calcPriceBySpaceSize = (
  width: number,
  height: number,
): { totalPrice; basePrice; serviceFee; unitPrice } => {
  const UNIT_PRICE = 100000; // 1평당 월 이용가격
  const SERVICE_FEE_PERCENT = 0.15; // 수수료 퍼센트 (15%)
  const size = calcFloorSize(width, height)?.py;
  const basePrice = size * UNIT_PRICE;
  const serviceFee = basePrice * SERVICE_FEE_PERCENT;
  const totalPrice = basePrice + serviceFee;

  return {
    totalPrice,
    basePrice,
    unitPrice: size > 1 ? UNIT_PRICE : basePrice,
    serviceFee,
  };
};

export const mappingDistrictMain = (input) => {
  const output = mappingDistrictMainData[input];

  return output ? output : '';
};
