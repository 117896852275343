import { defineStore } from 'pinia';
import { SpaceApi } from '@/api/SpaceApi';
import { useAnalytics } from '@/plugins/analytics';
import { getAirbridgeSemanticAttributes, getEventProperties, updateUserProperty } from '@/utils/analytics';
import { createLogger } from '@/utils/logger';
import { mappingDistrictMain } from '@/utils/space';

const debug = createLogger('app:stores:space');

interface SpaceState {
  cached: { [key: string]: any };
  currentTab: number;
}

export const useSpaceStore = defineStore({
  id: 'space',
  state: (): SpaceState => ({
    cached: {},
    currentTab: 0,
  }),
  getters: {
    getCached: (state) => (key: string) => {
      return state.cached[key];
    },
  },
  actions: {
    setCached(id: string, data: any) {
      const key = id?.toString();

      if (data === null) {
        this.cached[key] = null;
        delete this.cached[key];
        return;
      }

      if (this.cached[key]) {
        for (const column in data) {
          this.cached[key][column] = data[column];
        }
      } else {
        this.cached[key] = data;
      }
    },
    clearCachedItem(id: string) {
      const key = id;
      this.cached[key] = null;
      delete this.cached[key];
    },
    async fetchSpace(id: number) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerFindOne(id);
      this.setCached(id.toString(), data);
      return data;
    },
    setCurrentTab(tabIndex: number) {
      this.currentTab = tabIndex;
    },
    getCurrentTab() {
      return this.currentTab;
    },
    async createSpace(space: any) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerCreate(space);
      return data;
    },
    async updateSpace(id: number, space: any) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerUpdate(id, space);
      return data;
    },
    async deleteSpace(id: number) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerRemove(id);
      return data;
    },
    async addSpaceBookmark(space: any) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerBookmarkLinked(space.id);

      try {
        const { space_id, space_name, space_price, space_ft, space_size, space_facilities } = getEventProperties({
          space,
        });
        const { quantity, currency, productID, price, name } = getAirbridgeSemanticAttributes({ product: space });

        await updateUserProperty('total_add_like');
        useAnalytics().event('add_bookmark_space', {
          airbridge: {
            action: `${mappingDistrictMain(space.district_main)} ${space.district_sub}`,
            value: space_ft,
            semanticAttributes: {
              products: [
                {
                  quantity,
                  currency,
                  productID,
                  price,
                  name,
                },
              ],
            },
          },
          common: {
            space_id,
            space_price,
            space_size,
            space_facilities,
          },
        });
      } catch (error) {
        console.error(error);
      }

      return data;
    },
    async removeSpaceBookmark(space: any) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerBookmarkUnLinked(space.id);

      try {
        const { space_id, space_price, space_size, space_facilities } = getEventProperties({
          space,
        });
        await updateUserProperty('total_add_like');
        useAnalytics().event('delete_bookmark_space', {
          common: {
            space_id,
            space_price,
            space_size,
            space_facilities,
          },
        });
      } catch (error) {
        console.error(error);
      }

      return data;
    },
  },
});
